(function () {
    'use strict';
}());

app.controller('HeaderController', [
    '$rootScope',
    '$scope',
    '$window',
    '$state',
    '$timeout',
    '$location',
    '$transitions',
    'AlgoliaService',
    'CartService',
    'CompanyService',
    'FilterService',
    'OrderService',
    'SalesRepService',
    'UIService',
    'UserService',
    'IpLookupService',
    'INTL_SITES',
    'LIVE',
    '$uibModal',
    'OPEN_BROWSE_CUSTOMER_NUMBER',
    '$document',
    '$cookies',
    'DOMAIN',
    function ($rootScope, $scope, $window, $state, $timeout, $location, $transitions, AlgoliaService, CartService, CompanyService, FilterService, OrderService, SalesRepService, UIService, UserService, IpLookupService, INTL_SITES, LIVE, $uibModal, OPEN_BROWSE_CUSTOMER_NUMBER, $document, $cookies, DOMAIN) {
        $rootScope.initialPageLoad = true;

        $scope.setUser = function() {
            try {
                $scope.isOpenBrowse = true;
                $scope.user = UserService.getPersistedUser();
                $scope.isOpenBrowse = ($scope.user.customer_number == OPEN_BROWSE_CUSTOMER_NUMBER);
                if ($scope.user && $scope.user.can_multiview == true &&
                    ($scope.user.is_vallen || $scope.user.allowedCompanies)
                ) {
                    $rootScope.isMultiView = true;
                    $scope.displayCustomerSearch = true;
                }
            } catch(e) {
                $scope.isOpenBrowse = true;
            }
            UIService.setLogo($scope);
        };

        $scope.showCSR = function() {
            return ($scope.user && $scope.user.site_csr && $scope.user.site_csr.email && !$scope.user.vallen_xpress);
        };

        $scope.enableGifm = function() {
            return (
                $scope.user && $scope.user.can_submit_quotes &&
                !$scope.isOpenBrowse && !UserService.getIsPunchOut() &&
                (!$rootScope.currentCustomer || !$rootScope.currentCustomer.disable_gifm)
            );
        };

        $scope.enableOrderPad = function() {
            return !(UserService.getIsVallenXpress() || $rootScope.currentCustomer && $rootScope.currentCustomer.customer_number === '230118');
        };

        $scope.setUser();
        $scope.displayCustomerSearch = true;


        $timeout(function () {
            $scope.initialPageLoad = false;
        }, 800);

        $scope.showNavMenu = true;

        $scope.debounceTime = 80;
        $scope.showNavMenuRight = true;
        $scope.navSearchFocused = false;
        $scope.itemCount = CartService.getItemCount();

        $scope.search = {};
        $scope.searchFilter = {'name': 'All', 'slug': null};
        $scope.multiview = {input: null};
        $rootScope.$on('cart_update', function () {
            $scope.itemCount = CartService.getItemCount();
        });
        
        $timeout(function () {
            $rootScope.$broadcast('cart_update');
        }, 200);

        $transitions.onStart({}, function(transition) {
            $scope.navCollapsed = true;
        });

        $rootScope.setNavSearchFocused = function (active) {
            $scope.navSearchFocused = active;
            if (false === $scope.navSearchFocused) {
                if ($scope.navSearch !== undefined) {
                    $scope.navSearch.$setPristine();
                }

                $timeout(function () {
                    $scope.showNavMenu = true;
                    $scope.showNavMenuRight = true;
                }, 550);
            } else {
                $scope.showNavMenu = false;
                $scope.showNavMenuRight = false;
            }
        };

        $rootScope.getNavSearchFocused = function () {
            return $scope.navSearchFocused;
        };

        $scope.toggleDisplay = function () {
            $scope.displayCustomerSearch = !$scope.displayCustomerSearch;
            $rootScope.$broadcast('getAllowedCompanies', $scope.user);
            $scope.dropDownList = false;
            $scope.multiview.input = '';
        };

        $scope.setShowSearchTablet = function(bool) {
            $scope.showSearchTablet = bool;
        };

        $rootScope.doSearch = function (event, search) {
            event.preventDefault();
            if (!search.searchQuery || search.searchQuery.length <= 0) {
                $rootScope.$broadcast('notify', {message: "Please enter a search term."});
                return false;
            }

            $scope.setNavSearchFocused(false);
            var searchParams = {q: search.searchQuery};
            if ($scope.searchFilter && $scope.searchFilter.name !== 'All') {
                searchParams.department = $scope.searchFilter.name;
            } else {
                if (search.hasOwnProperty("searchCategory")) {
                    searchParams.department = search.searchCategory.name;
                }
            }

            if (search.hasOwnProperty("searchCategory")) {
                searchParams.department = search.searchCategory.name;
            }

            var params = $rootScope.mergeBlankParams(searchParams);
            $scope.showSuggestions = false;
            $state.go('new_catalog', params, {reload: true});
            search.searchQuery = null;
        };

        $scope.deptDropPanel = 0;
        $scope.setDeptDropPanel = function (val) {
            $scope.deptDropPanel = val;
        };
        $scope.insetDeptDropPanelClass = (!$scope.isMedium) ? 'inset' : 'inset-small';

        $scope.setSearchFilter = function (d) {
            $scope.searchFilter = d;
        };

        $scope.clearSearch = function() {
            $timeout(function() {
                $scope.search.searchQuery = '';
            }, 5);
        };

        $rootScope.$on('refreshSalesRepUser', function (e, a) {
            $scope.$broadcast('setCurrentCompany', a);
        });

        $rootScope.$on('login', function () {
            $scope.setUser();
            $scope.$broadcast('getCurrentCompany', $scope.user);
            $scope.$broadcast('getDefaultCompany', $scope.user);
        });

        $rootScope.$on('logout', function () {
            $rootScope.isMultiView = false;
            UIService.setLogo($scope);
        });

        setTimeout(function() {
            $window.onscroll();
        }, 1);

        $scope.showSuggestions = false;
        $scope.searchSuggestions = null;
        $scope.searchSuggestionsVendors = null;
        $scope.searchSuggestionsCategories = null;
        $scope.firstSearchReady = false;
        $scope.suggestSearch = function (search) {
            if (($rootScope.uaDevice.mobile && !$rootScope.uaDevice.tablet) || !search || search == '') {
                $scope.showSuggestions = false;
            } else {
                $scope.showSuggestions = true;

                AlgoliaService.searchSuggestions({
                    term: search,
                    facets: ['taxonomy.vendor.name', 'taxonomy.category.name'],
                    distinct: true,
                    hitsPerPage: 10
                }).then(function(data) {
                    $scope.firstSearchReady = true;
                    $scope.suggestionsLoading = false;
                    $scope.searchSuggestions = data.hits;
                    $scope.searchSuggestionsVendors = data.facets['taxonomy.vendor.name'];
                    $scope.searchSuggestionsCategories = data.facets['taxonomy.category.name'];
                }, function() {
                    $scope.firstSearchReady = true;
                    $scope.showSuggestions = false;
                    $scope.searchSuggestions = null;
                    $scope.searchSuggestionsVendors = null;
                    $scope.searchSuggestionsCategories = null;
                });
            }
        };

        $scope.getSearchSuggestions = function(startIndex) {
            if ($scope.searchSuggestions) {
                return $scope.searchSuggestions.slice(startIndex, startIndex+5);
            } else {
                return [];
            }
        };

        $scope.searchFocus = function() {
            if (!$scope.showSuggestions && $scope.searchSuggestions) {
                $scope.wasOverlayClicked = true;
                $scope.showSuggestions = true;
            }
        };

        $scope.overlayClicked = function() {
            $scope.wasOverlayClicked = true;
        };

        $scope.searchBlur = function() {

        };

        $document.bind('click', function() {
            if ($scope.showSuggestions) {
                if (!$scope.wasOverlayClicked) {
                    $scope.showSuggestions = false;
                }

                if(!$scope.$$phase) {
                    $scope.$evalAsync();
                }
            }

            $scope.wasOverlayClicked = false;
        });

        // Change Customer View
        $scope.multiview.input = "";
        $scope.$on('getCurrentCompany', function (e, user) {
            if (!$scope.user.customer_data) {
                $scope.user.customer_data = {};
            }
            $scope.multiview.input = $scope.user.customer_number + " - " + $scope.user.customer_data.name;
        });

        $scope.$on('getDefaultCompany', function (e, user) {
            $scope.user.default_customer_name = null;
            if (user.default_customer_number && user.can_multiview) {
                CompanyService.getCompanySummary(user.default_customer_number).then( function (company) {
                    $scope.user.default_customer_name = company.name;
                });
            }
        });

        $scope.dropDownType = 'Search';
        $rootScope.$on('getAllowedCompanies', function (e, user) {
            $scope.allowedCompanies = user.allowedCompanies && user.can_multiview ? user.allowedCompanies : {};
            if (Object.keys($scope.allowedCompanies).length > 150 || user.is_vallen) {
                $scope.dropDownType = 'Search';
            } else {
                $scope.dropDownType = 'Select';
                $rootScope.currentCompany = $scope.selectedCompany = $scope.allowedCompanies[user.customer_number];
            }
        });

        $scope.$on('setCurrentCompany', function (e, object) {
            if (object &&
                (object.customer_number !== $scope.user.customer_number)
            ) {
                $rootScope.$broadcast('open-loading-modal');
                $scope.user.customer_number = object.customer_number;
                $scope.displayCustomerSearch = true;
                $scope.user.set_multiview = true;
                UserService.putUser($scope.user, $scope.user.id, true).then( function () {
                    UserService.getUser($scope.user.id).then( function (data) {
                        $scope.setUser();
                        $rootScope.$broadcast('close-loading-modal');
                        $window.location.reload();
                    }).catch(function(err) {
                        console.error(err);
                    });
                });
            }
        });

        $rootScope.$on('refreshSalesRepUser', function (e, a) {
            $scope.$broadcast('setCurrentCompany', a);
        });

        if ($scope.user) {
            $scope.$broadcast('getCurrentCompany', $scope.user);
            $scope.$broadcast('getDefaultCompany', $scope.user);
            $rootScope.$broadcast('getAllowedCompanies', $scope.user);
            $scope.$broadcast('setCurrentCompany');
        }

        $scope.dropDownLoading = false;
        $scope.filterAllowedCompanies = function (input) {
            if (input) {
                $scope.dropDownLoading = true;

                var params = {
                    hitsPerPage: 100,
                    filters: 'customerNumber != 206106' // AZ8333 - exclude 206106 from multiview results
                };

                if (!$scope.user.is_vallen && $scope.user.allowedCompanies) {
                    var acctFilter = [];
                    angular.forEach($scope.user.allowedCompanies, function (a) {
                        acctFilter.push('customerNumber:' + a.customerNumber);
                    });
                    if (acctFilter.length) {
                        params.filters += ' AND (' + acctFilter.join(' OR ') + ')'; // for AZ8333
                        // params.filters = '(' + acctFilter.join(' OR ') + ')';
                    }
                }

                AlgoliaService.searchCustomers(input, params).then( function (data) {
                    $scope.dropDownList = data.hits;
                    $scope.dropDownLoading = false;
                });
            } else {
                $scope.dropDownList = false;
            }
        };

        $scope.hideCustomerResults = function() {
            $timeout(function(){
                $scope.dropDownList = false;
            }, 300);
        };

        $scope.setCompany = function () {
            if (!$scope.customerNumber) {
                $scope.customerNumber = $scope.user.customer_number;
                $scope.companyName = $scope.user.customer_name;
            }

            var object = {
                customer_number: $scope.customerNumber,
                name: $scope.companyName
            };
            $scope.$broadcast('setCurrentCompany', object);
        };

        $scope.setCompanyFromDropDown = function (company) {
            $scope.customerNumber = company.customerNumber;
            $scope.companyName = company.customerName;
            if (company.customerNameAlt) {
                $scope.companyName += ' ('+company.customerNameAlt+')';
            }
            $scope.multiview.input = $scope.customerNumber + " - " + $scope.companyName;
            $scope.dropDownList = false;
        };

        $scope.setDefaultCompany = function () {
            $scope.$broadcast('setCurrentCompany', {customer_number: $scope.user.default_customer_number});
        };

        $scope.geoRedirect = function() {
            if (!LIVE || UserService.getIsPunchOut() || $cookies.get('geoModal')) { return; }
            IpLookupService.getIpData().then(function (ipData) {
                if (ipData.country_code && INTL_SITES[ipData.country_code]) {
                    $scope.geoRedirectModal = $uibModal.open({
                        templateUrl: 'geoRedirect.html',
                        controller: 'GeoRedirectModalController',
                        scope: $scope,
                        windowClass: '',
                        resolve: {
                            ipData: function () {
                                return ipData;
                            }
                        }
                    });
                    $scope.geoRedirectModal.result.then(function(){
                        // modal closed
                    }, function() {
                        // modal dismissed
                    });
                }
            }).catch(function(err) {
                console.error(err);
            });
        };
        $scope.geoRedirect();
    }
]);


app.controller('FooterController', [
    '$rootScope',
    '$scope',
    'FooterService',
    function ($rootScope, $scope, FooterService) {
        $scope.menu = FooterService.getMenu();
        $scope.companyDescription = FooterService.getCompanyDescription();
        $scope.socialLinks = FooterService.getSocialMedia();
        $scope.hideMenuLink = function (link) {
            return link.loginRequired && !$rootScope.currentUser;
        };
    }
]);

app.controller('MaintenanceController', [
    '$rootScope',
    '$scope',
    'UIService',
    function ($rootScope, $scope, UIService) {
        $rootScope.hideWrapper = true;
        UIService.setLogo($scope);
    }
]);

app.controller('LoadingModalController', [
    '$rootScope',
    '$scope',
    '$timeout',
    function ($rootScope, $scope, $timeout) {
        $scope.closeLoadingModal = function () {
            // when modal is set to be closed, the following code fires.
            $scope.loadingModal.closed.then(function(){
                $rootScope.$broadcast('reset-scrolling');
            });
            // triggers the modal's close() function.
            $scope.loadingModal.close();
        };

        $rootScope.$on('close-loading-modal', function () {
            $timeout( function () {
                $scope.closeLoadingModal();
            }, 0);
        });
    }
]);

app.controller('LoginModalController', [
    '$rootScope',
    '$scope',
    'UserService',
    '$state',
    'destination',
    'destinationParams',
    function ($rootScope, $scope, UserService, $state, destination, destinationParams) {
        $scope.closeLoginModal = function () {
            $rootScope.$broadcast('reset-scrolling');
            $scope.loginModal.dismiss();
        };

        $scope.login = function (login) {
            $rootScope.$broadcast('open-loading-modal');
            $scope.loginAlert = null;

            UserService.login(login.username, login.password).then(function (authenticated) {
                if (authenticated) {
                    $scope.closeLoginModal();
                    $rootScope.$broadcast('close-loading-modal');
                    if (destination && destination !== 'contact-modal') {
                        $state.go(destination);
                    } else {
                        $state.reload();
                        if (destination === 'contact-modal') {
                            $scope.openContactModal(destinationParams.type);
                        }
                    }
                } else {
                    UserService.getUserStatus(login.username).then(function(user) {
                        $scope.loginAlert = user.errorMessage;
                    }).finally(function(){
                        $rootScope.$broadcast('close-loading-modal');
                    })
                }
            });
        };
    }
]);

app.controller('ContactModalController', [
    '$filter',
    '$rootScope',
    '$scope',
    '$uibModalInstance',
    'StatesService',
    'SupportService',
    'UserService',
    function ($filter, $rootScope, $scope, $uibModalInstance, StatesService, SupportService, UserService) {
        $scope.closeContactModal = function () {
            $rootScope.$broadcast('reset-scrolling');
            $uibModalInstance.dismiss('cancel');
        };

        $scope.formError = null;
        $scope.showRequired = '';
        $scope.states = StatesService.getStates();
        $scope.states.push({name: 'Other/International', abbr: '--'});

        $scope.inquiryTypes = SupportService.getInquiryTypes();
        if (!$scope.contact) {
            $scope.contact = {};
        }
        if ($scope.inquiryCode) {
            var selectedType = $scope.inquiryTypes.filter(function(type){
                return type.code === $scope.inquiryCode;
            });
            if (selectedType) {
                $scope.contact.mail_inquiry = selectedType[0];
            }
        }

        $scope.sendContact = function (contact) {
            if (!$scope.contactModal.$valid) {
                $scope.showRequired = 'ng-dirty';
                $scope.formError = 'Required fields are missing';
                return false;
            }

            if (contact.mail_inquiry) {
                contact.mail_type = contact.mail_inquiry.code;
                if (!contact.mail_subject) {
                    contact.mail_subject = contact.mail_inquiry.text;
                }
            }
            if (contact.mail_phone) {
                contact.mail_phone = $filter('phone')(contact.mail_phone);
            }

            var user = UserService.getPersistedUser();
            if (user) {
                contact.mail_userid = user.id;
                contact.username = user.username;
                contact.customer_number = user.customer_number;
                if (user.customer_data) {
                    contact.customer_name = user.customer_data.name;
                }
            }

            if ($scope.file !== undefined) {
                contact.mail_file_raw = $scope.file.base64;
                contact.mail_file_name = $scope.file.filename;
            }

            SupportService.sendMessage(JSON.stringify(contact)).then(function () {
                $rootScope.$broadcast('notify', {message: 'Your message is being sent to a Vallen Representative.'} );
                $uibModalInstance.close();
            }).catch(function (err) {
                $rootScope.$broadcast('notify', {message: 'There was an error processing your request.'} );
                console.error(err);
            });
        };
    }
]);

app.controller('ModalTermsAndConditionsController', [
    '$rootScope',
    '$scope',
    'WORDPRESS_URL',
    function ($rootScope, $scope, WORDPRESS_URL) {
        $scope.wp_url = WORDPRESS_URL;
    }
]);

app.controller('GeoRedirectModalController', [
    '$rootScope',
    '$scope',
    '$window',
    '$cookies',
    'ipData',
    'INTL_SITES',
    function ($rootScope, $scope, $window, $cookies, ipData, INTL_SITES) {
        $scope.ipData = ipData;
        var url = INTL_SITES[$scope.ipData.country_code];
        $scope.displayUrl = url.substr(url.indexOf('.') + 1);

        $scope.stay = function() {
            saveClick();
            $scope.geoRedirectModal.close();
        };

        $scope.go = function() {
            saveClick();
            $window.location.href = url;
        };

        var saveClick = function() {
            var d = new Date();
            $cookies.put('geoModal','1',{
                expires: new Date(d.getFullYear(), d.getMonth(), d.getDate()+1)
            });
        };
    }
]);

app.controller('DeliveryEstimateController', [
    '$scope',
    'DOMAIN',
    function ($scope, DOMAIN) {
        if (DOMAIN === 'boringsmith') {
            $scope.order_before = '5PM EST';
        } else {
            $scope.order_before = '7PM EST';
        }
    }
]);

app.controller('shoppingListModalInstanceCtrl', [
    '$rootScope',
    '$scope',
    '$window',
    '$uibModalInstance',
    'msg',
    'lists',
    'selectedItems',
    'ShoppingListService',
    'UserService',
    'options',
    'NgTableParams',
    function ($rootScope, $scope, $window, $uibModalInstance, msg, lists, selectedItems, ShoppingListService, UserService, options,NgTableParams) {

        $scope.promptModal = (options.promptModal !== undefined) ? options.promptModal : true;
        $scope.createListModal = (options.createListModal !== undefined) ? options.createListModal : false;
        $scope.addToListModal = (options.addToListModal !== undefined) ? options.addToListModal : false;
        $scope.closePrompt = (options.closePrompt !== undefined) ? options.closePrompt : false;
        $scope.list = (options.list !== undefined) ? options.list : {};
        if (!$scope.list.public) { $scope.list.public = false; }
        $scope.user = UserService.getPersistedUser();
        $scope.lists = lists;
        $scope.selectedItems = selectedItems;
        $scope.msg = msg;
        $scope.selected = {
            msg: $scope.msg
        };
        $scope.limits = [10, 25, 50, 100];

        $scope.tableParams = new NgTableParams({
            page: 1,
            count: $scope.limits[0]
        }, {
            dataset: $scope.lists
        });


        $scope.submitCreateList = function(listName){
            $rootScope.$broadcast('open-loading-modal');

            if (!$scope.user) {
                $rootScope.$broadcast('close-loading-modal');
                $rootScope.$broadcast('notify', {message: "You must be logged in to use Shopping Lists"});
                return;
            }

            var items = selectedItems;
            if (!items) {
                items = [];
            }

            ShoppingListService.postList(listName, items).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data) {
                    if ($rootScope.refreshData) {
                        $rootScope.refreshData();
                    }
                    $uibModalInstance.close(true);
                    if (items.length) {
                        var message = "The item(s) were successfully added to your shopping list.";
                        $rootScope.$broadcast('notify', {message: message});
                    }
                } else {
                    var errorMsg = "An error occurred while creating your Shopping List. Please try again.";
                    $rootScope.$broadcast('notify', {message: errorMsg});
                }
            });
        };

        $scope.addItemToList = function($event, list){
            $event.stopPropagation();
            $event.preventDefault();
            $rootScope.$broadcast('open-loading-modal');

            angular.forEach(selectedItems, function (item) {
                list.items.push(item);
            });

            ShoppingListService.updateList(list).then(function (data) {
                $rootScope.$broadcast('close-loading-modal');
                if (data) {
                    $uibModalInstance.close(true);
                    var message = "The item(s) were successfully added to your shopping list.";
                    $rootScope.$broadcast('notify', {message: message});
                } else {
                    var errorMsg = "An error occurred while creating your Shopping List. Please try again.";
                    $rootScope.$broadcast('notify', {message: errorMsg});
                }
            });
        };

        $scope.backToPromptModal = function(){
            $scope.promptModal = true;
            $scope.createListModal = false;
            $scope.addToListModal = false;
        };

        $scope.createShoppingList = function () {
            $scope.promptModal = false;
            $scope.createListModal = true;
            $scope.addToListModal = false;
        };

        $scope.addToExistingShoppingList = function () {
            $scope.promptModal = false;
            $scope.createListModal = false;
            $scope.addToListModal = true;
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
]);

app.controller('myAccountNavController', [
    '$rootScope',
    '$scope',
    'AccountService',
    '$state',
    function ($rootScope, $scope, AccountService, $state) {
        var loadedRoute = $state.current.name;
        $scope.dropdownId = [];
        $scope.accountMenu = AccountService.getAccountMenu($scope.user, $rootScope.workflow.currency);
        // Also opens specific dropdown based on loaded route.
        angular.forEach($scope.accountMenu, function(value, key){
            for (var i = 0; i < value.routes.length; i++) {
                if (loadedRoute == value.routes[i].route) {
                    $scope.dropdownId.push($scope.accountMenu[key].id);
                }
            }
        });

        $scope.enableDropdown = function(id) {
            if ($scope.dropdownId.includes(id)) {
                var index = $scope.dropdownId.indexOf(id);
                $scope.dropdownId.splice(index, 1);
            } else {
                $scope.dropdownId.push(id);
            }

        }
    }
]);

